<template>
  <div id="app">
    <!-- <div class="main_bg"></div> -->
    <Header />
    <template>
      <div class="ma-0 pa-3" style="overflow: hidden;">
        <v-data-table
          dense
          :headers="headers"
          :items="orders"
          :hide-default-header="false"
          :hide-default-footer="true"
          :items-per-page="-1">
          <template v-slot:item="{ item }">
            <tr>
              <td style="vertical-align: top;">
                <v-img
                  contain
                  class="ma-2"
                  style="cursor: pointer"
                  :eager="true"
                  :src="getImageUrl(item)"
                  @click="showImage(item)"
                  lazy-src="@/assets/noimage.png"
                  aspect-ratio="1"
                  min-width="80"
                  max-width="80"
                  max-height="80">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align-center
                      justify="center"
                    >
                      <v-progress-circular  v-show="loading"
                        indeterminate
                        color="gray lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </td>
              <!-- <td style="vertical-align: top;">
                <v-btn
                  :width="130"
                  @click="acceptOrder(item)"
                  class="ma-2 white--text"
                  color="green darken-2">
                  {{ $t('accept.accept_order') }}
                </v-btn><br />
                <v-btn
                  :width="130"
                  @click="cancelOrder(item)"
                  class="ma-2 mt-0"
                  color="gray darken-2">
                  {{ $t('accept.cancel_order') }}
                </v-btn>
              </td> -->
              <td style="vertical-align: top; padding: 5px;">{{ item.orderId }}</td>
              <td style="vertical-align: top; padding: 5px;">{{ item.branchName }}</td>
              <td style="vertical-align: top; padding: 5px;">{{ item.orderDate }}</td>
              <td style="vertical-align: top; padding: 5px;">{{ item.deliveryDatetime }}</td>
              <!-- <td>{{ item.cprcode }}</td> -->
              <td style="vertical-align: top; padding: 5px;">{{ item.cprcode }} - {{ item.productName }}</td>
              <!-- <td>{{ item.shippingType }} / {{ item.deliveryMode }}</td> -->
              <td style="vertical-align: top; text-align: right; padding: 5px;">{{ item.qty }}</td>
              <td style="vertical-align: top; text-align: right; padding: 5px;">{{ numberFormat(item.price,2) }}</td>
              <td style="vertical-align: top; text-align: right; padding: 5px;">{{ numberFormat(item.amount,2) }}</td>
              <td style="vertical-align: top; padding: 5px;">{{ item.filterDatetime }}</td>
              <!-- <td>{{ item.shippingFirstName }}</td> -->
            </tr>
          </template>
        </v-data-table>

        <div style="text-align: center;" v-if="$store.state.last_key">
          <v-btn :width="150" @click="loadMore" class="primary">
            <v-icon left>refresh</v-icon>
            <span>{{ $t('message.load_more') }}</span>
          </v-btn>
        </div>
      </div>
    </template>
    <Bottom />

    <ShowOverlay 
      v-model="isShowOverlay">
    </ShowOverlay>
    <ShowMessage 
      v-model="isShowMessage" 
      :msg="showmessage_msg"
      :width="showmessage_width"
      :callBack="showmessage_callback">
    </ShowMessage>
    <ShowImage 
      v-model="isShowImage" 
      :msg="showimage_msg"
      :imageName="showimage_image_name"
      :width="showimage_width"
      :callBack="showimage_callback">
    </ShowImage>
    <ShowConfirm 
      v-model="isShowConfirm" 
      :msg="showconfirm_msg"
      :width="showconfirm_width"
      :no_callback="showconfirm_no_callback"
      :yes_callback="showconfirm_yes_callback">
    </ShowConfirm>
  </div>
</template>

<script>
import { imageUrl } from "@/services/constants";
import Header from "@/components/Header";
import Bottom from "@/components/Bottom";
import ShowOverlay from "@/components/ShowOverlay";
import ShowMessage from "@/components/ShowMessage";
import ShowImage from "@/components/ShowImage";
import ShowConfirm from "@/components/ShowConfirm";
import api from "@/services/api";

export default {
  name: "Cancel",
  data() {
      return {
          loading: false,
          isShowOverlay: false,

          isShowMessage: false,
          showmessage_msg: "",
          showmessage_width: 400,
          showmessage_callback: null,

          isShowImage: false,
          showimage_msg: "",
          showimage_image_name: "",
          showimage_width: 400,
          showimage_callback: null,

          isShowConfirm: false,
          showconfirm_msg: "",
          showconfirm_width: 400,
          showconfirm_no_callback: null,
          showconfirm_yes_callback: null,
          orderConfirm: null,

          orders: [],
          headers: [
              {
                  text: "Image",
                  align: "left",
                  sortable: false,
                  value: "image"
              },
              // {
              //     text: "",
              //     align: "left",
              //     sortable: false,
              //     value: "action",
              // },
              { text: "OrderId", value: "orderId" },
              { text: "Branch", value: "branchName" },
              { text: "OrderDate", value: "orderDate" },
              { text: "DeliveryDate", value: "deliveryDatetime" },
              // { text: "PrCode", value: "cprcode" },
              { text: "ProductName", value: "productName" },
              // { text: "ShippingType", value: "shippingType" },
              { text: "Qty", value: "qty", align: "right" },
              { text: "Price", value: "price", align: "right" },
              { text: "Amount", value: "amount", align: "right" },
              { text: "CancelDate", value: "filterDatetime" },
          ],
      };
  },
  components: {
      Header,
      Bottom,
      ShowOverlay,
      ShowMessage,
      ShowImage,
      ShowConfirm
  },
  methods: {
      showMessage(showmessage_msg, showmessage_width) {
        this.showmessage_msg = showmessage_msg;
        this.showmessage_width = showmessage_width;
        this.isShowMessage = true;
      },
      showImage(item) {
        this.showimage_callback = null;
        this.showimage_msg = item.productName;
        this.showimage_image_name = item.image;
        this.showimage_width = 400;
        this.isShowImage = true;
      },
      showConfirm(showconfirm_msg, showconfirm_width) {
        this.showconfirm_msg = showconfirm_msg;
        this.showconfirm_width = showconfirm_width;
        this.isShowConfirm = true;
      },
      // async onAcceptOrderYesCallBack() {
      //   try {
      //     this.showmessage_callback = null;
      //     if (this.orderConfirm) {
      //       this.isShowOverlay = true;
      //       let result = await api.updateVendorDeliveryStatus(this.orderConfirm.orderId, this.orderConfirm.cprcode, 1);
      //       if (result?.statusCode == 200) await this.loadData();
      //     }
      //   } catch (error) {
      //     this.showMessage(error.response.data, 400);
      //   } finally {
      //     setTimeout(() => {
      //       this.isShowOverlay = false;
      //     }, 100);
      //   }
      // },
      // async onCancelOrderYesCallBack() {
      //   try {
      //     this.showmessage_callback = null;
      //     if (this.orderConfirm) {
      //       this.isShowOverlay = true;
      //       let result = await api.updateVendorDeliveryStatus(this.orderConfirm.orderId, this.orderConfirm.cprcode, 4);
      //       if (result?.statusCode == 200) await this.loadData();
      //     }
      //   } catch (error) {
      //     this.showMessage(error.response.data, 400);
      //   } finally {
      //     setTimeout(() => {
      //       this.isShowOverlay = false;
      //     }, 100);
      //   }
      // },
      numberFormat(value, decimal) {
        return parseFloat(value?? 0).toLocaleString("th-TH", {"minimumFractionDigits":decimal,"maximumFractionDigits":decimal});
      },
      getImageUrl(item) {
        return `${imageUrl}/${item.image}`;
      },
      // acceptOrder(item) {
      //   try {
      //     this.showmessage_callback = null;
      //     this.orderConfirm = null;
      //     this.showconfirm_no_callback = null;
      //     this.showconfirm_yes_callback = null;
      //     if (item.orderId) {
      //       this.orderConfirm = item;
      //       this.showconfirm_yes_callback = this.onAcceptOrderYesCallBack;
      //       this.showConfirm(`${this.$i18n.getLocaleMessage(this.$i18n.locale).accept.accept_order} ${item.orderId} - ${item.productName}`, 400);
      //     }
      //   } catch (error) {
      //     this.showMessage(error.response.data, 400);
      //   }
      // },
      // cancelOrder(item) {
      //   try {
      //     this.showmessage_callback = null;
      //     this.orderConfirm = null;
      //     this.showconfirm_no_callback = null;
      //     this.showconfirm_yes_callback = null;
      //     if (item.orderId) {
      //       this.orderConfirm = item;
      //       this.showconfirm_yes_callback = this.onCancelOrderYesCallBack;
      //       this.showConfirm(`${this.$i18n.getLocaleMessage(this.$i18n.locale).accept.cancel_order} ${item.orderId} - ${item.productName}`, 400);
      //     }
      //   } catch (error) {
      //     this.showMessage(error.response.data, 400);
      //   }
      // },
      async loadData() {
        let last_key = this.$store.state.last_key ?? "";
        let result = await api.getVendorOrderList(4, 10, last_key ?? "");
        if (result?.statusCode == 200) {
          await this.$store.dispatch("setLastKey", result.data?.last_key ?? "");
          if (last_key == "")
            this.orders = result.data?.items ?? [];
          else
            this.orders = this.orders.concat(result.data?.items ?? []);
        }
      },
      async loadMore() {
        try {
          this.showmessage_callback = null;
          this.isShowOverlay = true;
          // let sHeight = this.pageHeight();
          await this.loadData();
          // this.$vuetify.goTo(sHeight);
        } catch (error) {
          this.showMessage(error.response.data, 400);
        }
        finally {
          setTimeout(() => {
            this.isShowOverlay = false;
          }, 100);
        }
      },
  },
  async mounted() {
    try {
      this.showmessage_callback = null;
      this.isShowOverlay = true;
      await this.$store.dispatch("setLastKey", "");
      await this.loadData();
    } catch (error) {
      this.showMessage(error.response.data, 400);
    }
    finally {
      setTimeout(() => {
        this.isShowOverlay = false;
      }, 100);
    }
  }
}

</script>

<style scoped>
.main_bg {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
  color: #ffffff;
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(48,63,159,1) 50%, rgba(0,212,255,1) 100%);
  background-size:100% 100%;
  background-repeat: no-repeat;
  position: fixed;
}
.v-btn {
  text-transform:none !important;
}
</style>